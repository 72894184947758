import { PageProps } from 'gatsby';
import React from 'react';

import { useCustomMixpanel } from '@services/Mixpanel';
import { BoilerDetailsPage } from '@src/modules';

const Boiler = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  return <BoilerDetailsPage {...props} mixpanel={mixpanel} />;
};

export default Boiler;
